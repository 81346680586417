<template>
  <v-tooltip v-if="accountStore.isAdmin" top>
    <template #activator="{ on, attrs }">
      <v-icon x-small color="red" v-bind="attrs" v-on="on">
        fas fa-eye-slash
      </v-icon>
    </template>
    <span>Under NDA/MC</span>
  </v-tooltip>
</template>

<script>
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  computed: {
    ...mapStores(useAccountStore),
  },
}
</script>
