<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn icon color="rgba(0, 0, 0, 0.54)" x-small :href="`/`">
          <v-icon small> fa-house </v-icon>
        </v-btn>
      </span>
    </template>
    <span>Home</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "HomeButton",
}
</script>
