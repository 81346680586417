<template>
  <v-treeview
    v-model="selectedFeatureFlags"
    :multiple-active="true"
    :active="selectedFeatureFlags"
    selectable
    item-key="value"
    :items="featureFlagsTreeViewItems"
    item-disabled="locked"
    selection-type="independent"
    dense
  >
    <template #label="{ item }">
      <v-list-item
        dense
        :disabled="item.locked"
        @click.stop="onListItemLabelClicked(item)"
        >{{ item.name }}</v-list-item
      >
    </template>
  </v-treeview>
</template>

<script>
export default {
  name: "FeatureFlagsTree",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    featureFlagsTreeViewItems() {
      return this.items.map(({ name, value, children }) => ({
        name,
        value,
        locked: this.readonly,
        children: children?.map((child) => ({
          name: child.name,
          value: child.value,
          locked: this.readonly || !this.selectedFeatureFlags.includes(value),
        })),
      }))
    },
    selectedFeatureFlags: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit("input", newValue)
      },
    },
  },
  methods: {
    onListItemLabelClicked(item) {
      if (this.readonly || item.locked) {
        return
      }

      if (this.selectedFeatureFlags.includes(item.value)) {
        this.selectedFeatureFlags = this.selectedFeatureFlags.filter(
          (feature) => feature !== item.value
        )
      } else {
        this.selectedFeatureFlags.push(item.value)
      }
    },
  },
}
</script>

<style>
.v-treeview-node__checkbox.v-icon {
  font-size: 1rem !important;
}
.v-treeview-node__checkbox {
  width: 1px !important;
}
.v-treeview--dense,
.v-treeview-node__root,
.v-list-item--dense {
  min-height: 30px !important;
}
</style>
