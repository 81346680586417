<template>
  <div>
    <v-overlay :value="isOverlayImgLoading" :absolute="true" opacity="0.6">
      <ECol>
        <span
          v-if="overLayImgProgress >= 0 && overLayImgProgress < 100"
          class="d-block pa-0 text-h5 text-capitalize"
        >
          Downloading map assets {{ overLayImgProgress }}%
        </span>
        <span v-else class="d-block pa-2 text-h5 text-capitalize"
          >Rendering assets on the map</span
        >
        <e-spinner class="e-mx-auto my-2" size="SixXl" :color="false" />
      </ECol>
    </v-overlay>
    <gmap-map
      ref="gmap"
      :options="{
        zoomControl: true,
        controlSize: 26,
      }"
      :center="mapCenter"
      :style="containerStyle"
      @zoom_changed="zoomChanged"
    >
      <div slot="visible">
        <div class="camera-map__overlap-autocomplete">
          <gmap-autocomplete
            v-if="isEditing"
            class="camera-map__autocomplete"
            :select-first-on-enter="true"
            @place_changed="setPlace"
          />
        </div>
      </div>
      <div v-if="map">
        <CameraMarkerWithFov
          v-model="selectedMarker"
          :showFov="drawFov"
          :hovered="isHovered && isEditing"
          :isEditable="isEditing"
          hoverColor="grey"
          @mouseover="isHovered = true"
          @mouseout="isHovered = false"
        />
      </div>
    </gmap-map>
  </div>
</template>

<script>
import CameraMarkerWithFov from "@evercam/shared/components/CameraMarkerWithFov"
import { createCustomOverlay } from "@evercam/shared/utils"
import axios from "axios"

/*global google*/

export default {
  name: "CameraMapFov",
  components: {
    CameraMarkerWithFov,
  },
  props: {
    marker: {
      type: [Object, null],
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: "auto",
    },
    selectedMapView: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mapCenter: {
        lat: 52.51195,
        lng: 6.089625,
      },
      map: null,
      shape: null,
      fovPoints: [],
      polygonPointStyle: {},
      fovCenterStyle: {},
      markerIcon: null,
      defaultZoomLevel: 17.9,
      zoom: 17.9,
      isHovered: false,
      isOverlayImgLoading: false,
      historicalOverlay: null,
      overLayImgProgress: 0,
    }
  },
  computed: {
    selectedMarker: {
      get() {
        return this.marker
      },
      set(v) {
        this.$emit("marker-change", v)
      },
    },
    drawFov() {
      return this.zoom >= 16
    },
    containerStyle() {
      return {
        height:
          typeof this.height === "number" ? `${this.height}px` : this.height,
        width: "auto",
      }
    },
  },
  watch: {
    selectedMapView(v) {
      this.setMapView(v)
    },
  },
  mounted() {
    this.setupMarker()
    this.setMapView(this.selectedMapView)
  },
  methods: {
    zoomChanged(zoom) {
      this.zoom = zoom
    },
    setupMarker() {
      if (!this.selectedMarker) {
        this.selectedMarker = {
          isOnline: true,
          location: {
            lat: 0,
            lng: 0,
          },
          heading: 0,
          fovAngle: 45,
          fovRadius: 45,
        }
      }
      this.$refs.gmap.$mapPromise.then((map) => {
        this.map = map
        if (this.selectedMarker) {
          this.centerMapOnMarker()
        }
      })
    },
    centerMapOnMarker() {
      this.map.setCenter(
        new google.maps.LatLng(
          this.selectedMarker?.location?.lat,
          this.selectedMarker?.location?.lng
        )
      )
      this.map.setZoom(this.defaultZoomLevel)
    },
    setPlace(place) {
      this.selectedMarker.location = {
        lat: place.geometry?.location.lat(),
        lng: place.geometry?.location.lng(),
      }

      this.centerMapOnMarker()
    },
    async fetchImage(url) {
      this.isOverlayImgLoading = true
      const response = await axios.get(url, {
        responseType: "blob",
        onDownloadProgress: (ev) => {
          this.overLayImgProgress = Math.floor((ev.loaded / ev.total) * 100)
        },
      })
      const blob = response.data
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      const image64 = await new Promise((resolve, reject) => {
        reader.onload = () => {
          const base64Image = reader.result
          if (base64Image) {
            resolve(base64Image)
          } else if (reader.error) {
            reject(new Error("Error While Loading the Map Asset"))
          }
        }
      })

      return image64
    },
    async setMapView(mapView) {
      if (!mapView) {
        return
      }
      this.$refs.gmap.$mapPromise.then(async (map) => {
        this.historicalOverlay?.onRemove()
        if (!mapView.image) {
          return
        }
        this.isOverlayImgLoading = true
        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(mapView.south, mapView.west),
          new google.maps.LatLng(mapView.north, mapView.east)
        )
        if (!mapView?.image64) {
          mapView.image64 = await this.fetchImage(mapView.image)
        }
        const overlay = createCustomOverlay(bounds, mapView.image64)
        overlay.setMap(map)
        this.mapType = mapView.mapStyle
        this.historicalOverlay = overlay
        const checkOverlayDraw = () => {
          if (overlay.div?.children.length > 0) {
            this.isOverlayImgLoading = false
          } else {
            setTimeout(checkOverlayDraw, 100)
          }
        }
        checkOverlayDraw()
      })
    },
  },
}
</script>

<style lang="scss">
.camera-map {
  &__overlap-autocomplete {
    top: 10px;
    left: 180px;
    width: calc(100% - 500px);
    z-index: 100;
    position: absolute;
  }
  &__autocomplete {
    padding: 10px;
    border-radius: 5px;
    background: white;
    border: none;
    &:focus {
      outline: none;
    }
  }
  .vue-map-container {
    height: 350px;
    max-height: 350px;
    width: auto;
    .vue-map {
      height: 350px !important;
    }
  }
}
</style>
