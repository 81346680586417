<template>
  <div v-if="item.cameras && item.cameras.length > 0">
    <div v-for="(camera, index) in item.cameras" :key="camera.exid">
      <NdaIcon v-if="isNDAProject(camera, item)" />
      <span
        class="cursor-pointer primary--text"
        @click="openCameraDialog(camera)"
      >
        {{
          index !== item.cameras.length - 1 ? `${camera.name},` : camera.name
        }}
        <br v-if="(index + 1) % itemsPerLine == 0" />
      </span>
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import NdaIcon from "@/components/NdaIcon"

export default {
  components: {
    NdaIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    itemsPerLine: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    openCameraDialog(value) {
      this.$emit("open-camera-dialog", value.exid)
    },
    isNDAProject(camera, item) {
      return (
        camera?.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc) ||
        item?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
      )
    },
  },
}
</script>
