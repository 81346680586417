<template>
  <div class="d-flex hours-heatmap overflow-x-auto">
    <div
      v-for="hour in dayHours"
      :key="hour"
      class="d-flex w-100 justify-center align-center hours-heatmap__item"
      :class="{
        'hours-heatmap__item--selected': isSelectableHour(hour),
        'hours-heatmap__item--has-data': hours.includes(hour),
        'hours-heatmap__item--highlighted': highlightedHours.includes(hour),
      }"
      @click="selectHour(hour)"
    >
      <span class="caption">
        {{ `${hour}`.length > 1 ? hour : `0${hour}` }}h
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HoursPicker",
  props: {
    hours: {
      type: Array,
      default: () => [],
    },
    highlightedHours: {
      type: Array,
      default: () => [],
    },
    selectedHour: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      dayHours: new Array(24).fill(0).map((_, hour) => hour),
    }
  },
  methods: {
    isSelectableHour(hour) {
      return this.selectedHour === hour && this.hours.includes(hour)
    },
    selectHour(hour) {
      if (!this.hours.includes(hour)) {
        return
      }
      this.$emit("change", hour)
    },
  },
}
</script>

<style lang="scss">
.hours-heatmap {
  &__item {
    height: 16px;
    margin: 2px;
    position: relative;
    cursor: pointer;
    border-radius: 2px;
    min-width: 35px;
    background: #ebedf0;
    &--selected {
      box-shadow: 0 0 0 1.5px #0090ff;
    }
    &--has-data {
      background: #c0ddf9;
      &:hover {
        box-shadow: 0 0 0 1.5px #5c6d97;
      }
    }
  }
}
</style>
