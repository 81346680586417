<template>
  <v-card
    v-if="inline"
    class="dialog-tabs-container--inline"
    flat
    :loading="isLoading"
  >
    <v-card-title class="pa-0 pl-2 dialog-tabs-container__header">
      <slot name="header" />
    </v-card-title>
    <v-tabs
      v-model="selectedTab"
      :vertical="$vuetify.breakpoint.mdAndUp"
      :show-arrows="$vuetify.breakpoint.smAndDown"
      class="dialog-tabs-container__tabs custom-tabs"
      active-class="blue lighten-5"
    >
      <slot name="v-tabs-content" />
    </v-tabs>
  </v-card>
  <v-dialog
    v-else
    v-model="dialog"
    eager
    fullscreen
    hide-overlay
    scrollable
    persistent
    transition="fade-transition"
    :content-class="`dialog-tabs-container ${dialogContentClass}`"
    no-click-animation
    @keydown.esc="$emit('close')"
  >
    <v-card flat :loading="isLoading">
      <v-card-title class="pa-0 pl-2 dialog-tabs-container__header">
        <slot name="header" />
      </v-card-title>
      <v-tabs
        v-model="selectedTab"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :show-arrows="$vuetify.breakpoint.smAndDown"
        class="dialog-tabs-container__tabs custom-tabs"
        active-class="blue lighten-5"
      >
        <slot name="v-tabs-content" />
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"

export default {
  name: "DialogTabsContainer",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentTab: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapStores(useLayoutStore),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    selectedTab: {
      get() {
        return this.currentTab
      },
      set(value) {
        this.$emit("update:current-tab", value)
      },
    },
    dialogContentClass() {
      if (!this.layoutStore.isMiniSidebar) {
        return ""
      }

      return "dialog-tabs-container--fullscreen"
    },
  },
}
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
@import "~vuetify/src/styles/styles.sass";
@import "assets/vars";
.dialog-tabs-container {
  overflow: hidden;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: calc(100vw - #{$sidebar-width});
    margin-left: $sidebar-width !important;
  }

  &__header {
    box-shadow: 0px 5px 12px -13px #0000007a;
    z-index: 1;
  }
  &__tabs {
    & > .v-tabs-bar {
      border-right: 1px solid #e0e0e0;
      box-shadow: 5px 0px 11px -12px #000000b5;
      z-index: 1;
    }
    & > .v-tabs-items {
      @include custom-scrollbar(rgb(186, 186, 186), rgb(231, 231, 231));
      overflow-y: auto;
      max-height: calc(100vh - 57px);
      height: calc(100vh - 57px);
    }
    .v-tab {
      text-transform: none;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-tabs-bar {
        border-bottom: thin solid #e0e0e0;
      }
    }
  }

  &--fullscreen {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: calc(100vw - #{$sidebar-width--sm});
      margin-left: $sidebar-width--sm !important;
    }
  }
}
</style>
