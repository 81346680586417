<template>
  <div>
    <v-btn
      v-if="!canEdit"
      icon
      class="mx-1"
      x-small
      color="primary"
      @click.native.stop="$emit('start-editing')"
    >
      <v-icon x-small> fas fa-pencil-alt </v-icon>
    </v-btn>
    <v-btn
      v-if="canEdit"
      color="primary"
      class="mx-1"
      icon
      x-small
      @click.native.stop="$emit('save')"
    >
      <EvercamLoadingAnimation v-if="isSaving" size="Md" />
      <v-icon v-else>fas fa-save</v-icon>
    </v-btn>
    <v-btn
      v-if="canEdit"
      color="primary"
      icon
      x-small
      @click.native.stop="$emit('cancel')"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>
  </div>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "EditingButton",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
