<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        x-small
        color="primary"
        v-bind="attrs"
        target="_blank"
        class="zoho-icon"
        :disabled="!zohoId"
        :href="href"
        v-on="on"
      >
        <ESvgIcon icon="zoho-crm" :color="color || defaultColor" />
      </v-btn>
    </template>
    <span>Click to open in Zoho</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "OpenInZohoButton",
  props: {
    zohoId: {
      type: [String, Number, null],
      default: null,
    },
    type: {
      type: [String, null],
      default: null,
    },
    color: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    defaultColor() {
      return this.zohoId ? this.$vuetify.theme.themes.light.primary : "grey"
    },
    href() {
      let route = ""
      switch (this.type) {
        case "camera":
          route = `tab/CustomModule4/${this.zohoId}`
          break
        case "user":
          route = `tab/Contacts/${this.zohoId}`
          break
        case "project":
          route = `tab/CustomModule12/${this.zohoId}`
          break
        case "company":
          route = `tab/Accounts/${this.zohoId}`
          break
        default:
          break
      }

      return `${this.$config.public.zohoCrmUrl}/${route}`
    },
  },
}
</script>
